import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

import Vue from 'vue';
import { v4 } from 'uuid';

// Application
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import CalculateCustomGoalMonthlyPaymentCalculationQuery
  from '@/modules/flagship/custom-goal-calculations/application/querys/calculate-custom-goal-monthly-payment-calculation-query';
import CalculateCustomGoalMaximumInitialAmountQuery
  from '@/modules/flagship/custom-investor-goal-calculator/application/queries/calculate-custom-goal-maximum-initial-amount-query';

// Domain
import {
  CustomInvestorGoalDto,
} from '@/modules/flagship/custom-investor-goal/domain/dtos/custom-investor-goal-dto';
import { GetInterestRateByFixedTimeService } from '@/modules/flagship/investor-goal/associated-product/domain/service/get-interest-rate-by-fixed-time-service';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import {
  maxLengthGoalNameRule,
  maxValueRule,
  minValueRule,
  requiredRule,
} from '@/vue-app/utils/form-rules';

export default class AddCustomInvestorGoalWizardCustomizeGoalModel {
  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.CALCULATE_CUSTOM_GOAL_MONTHLY_PAYMENT_CALCULATION_QUERY)
  private readonly calculate_monthly_payment_query!:
    CalculateCustomGoalMonthlyPaymentCalculationQuery;

  @Inject(TYPES.CALCULATE_CUSTOM_GOAL_MAXIMUM_INITIAL_AMOUNT_QUERY)
  private readonly calculate_maximum_initial_amount!: CalculateCustomGoalMaximumInitialAmountQuery;

  @Inject(TYPES.GET_INTEREST_RATE_BY_FIXED_TYME_SERVICE)
  private readonly get_interest_rate_by_fixed_time!: GetInterestRateByFixedTimeService;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  i18n_namespace = 'components.goals-dashboard.add-goals.add-custom-investor-goal-wizard-customize-goal';

  private custom_investor_goal_dto: CustomInvestorGoalDto = {
    id: v4(),
    goal_name: '',
    desired_amount: 0,
    fixed_time_adjusted: '',
    investor_goal: {
      investor_profile_id: '',
      initial_amount: 0,
      accumulated_amount: 0,
      monthly_required_amount: 0,
    },
    custom_goal_type: {
      id: '',
      icon_name: '',
      name: '',
      label: '',
      description: '',
      created_at: '',
      updated_at: '',
    },
  }

  goal_name = '';

  goal_total = '0';

  initial_amount = '0';

  time_to_reach_goal = 0;

  wants_years = false;

  monthly_payment_calculation = 0;

  maximum_initial_amount = 100000000;

  interest_rate = 0;

  is_valid_form = false;

  input_rules = {
    other_goal_name: [requiredRule, (value: string) => maxLengthGoalNameRule(26, value)],
    goal_total: [
      (value: string) => (
        parseFloat(value.replace(/[^0-9.-]/g, '')) > 0
        || this.translate_errors('utils.form-rules.minimum_error', { value: '$1.00 MXN' })
      ),
    ],
    initial_amount: [
      requiredRule,
      (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''), '$0.00 MXN', 0),
      (value: string) => maxValueRule(
        value.replace(/[^0-9.-]/g, ''),
        currencyFormat(this.maximum_initial_amount),
        this.maximum_initial_amount,
      )],
  };

  timer?: NodeJS.Timer;

  is_loading = false;

  view!: Vue;

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  translate_errors = (message: string, values?: Values) => this.translator.translate(`${message}`, values);

  get btn_years_text() {
    return this.translate(this.wants_years ? 'caption_2' : 'caption');
  }

  get monthly_payment() {
    return Math.trunc(this.monthly_payment_calculation).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  get userWantsYears() {
    return this.wants_years ? 20 : 11;
  }

  get is_continue_btn_disabled() {
    return !(this.goal_name !== '' && this.goal_total !== '$0 MXN')
      || !(this.goal_name.length < 26) || !this.is_valid_form || this.is_loading;
  }

  get goal_total_value() {
    return this.goal_total.toString().replace(/[^0-9.]/g, '');
  }

  get goal_initial_amount() {
    return this.initial_amount.toString().replace(/[^0-9.]/g, '');
  }

  get fixed_time_adjusted() {
    return this.wants_years
      ? this.time_to_reach_goal * 12
      : this.time_to_reach_goal;
  }

  setInterestRate = (wants_years: boolean) => {
    this.interest_rate = this.get_interest_rate_by_fixed_time.getInterestRate(wants_years);
  }

  toggleWantsYears = async () => {
    this.wants_years = !this.wants_years;
    this.setInterestRate(this.wants_years);
    await this.calculateMonthlyPayment();
  }

  initialize = async (custom_investor_goal_dto: CustomInvestorGoalDto) => {
    this.view.$emit('loadingStep', true);
    this.custom_investor_goal_dto = custom_investor_goal_dto;
    this.goal_name = custom_investor_goal_dto.custom_goal_type!.label;
    this.goal_total = String(Math.trunc(Number(custom_investor_goal_dto.desired_amount)));
    this.initial_amount = String(Math.trunc(Number(custom_investor_goal_dto
      .investor_goal.initial_amount)));
    this.wants_years = Number(custom_investor_goal_dto.fixed_time_adjusted) >= 11;
    this.time_to_reach_goal = Number(custom_investor_goal_dto.fixed_time_adjusted) >= 11
      // eslint-disable-next-line max-len
      ? Number(custom_investor_goal_dto.fixed_time_adjusted) / 12 : Number(custom_investor_goal_dto.fixed_time_adjusted);
    await this.getInvestorProfile();
    this.view.$emit('loadingStep', false);
  }

  getInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute();
      this.custom_investor_goal_dto.investor_goal.investor_profile_id = investor_profile.id;
      this.setInterestRate(false);
      await this.calculateMonthlyPayment();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investor_profile'));
    }
  };

  delay = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      await this.calculateMonthlyPayment();
    }, 1000);
  }

  calculateMaximumInitialAmount = async () => {
    try {
      this.is_loading = true;
      if (parseFloat(this.goal_total_value) > 0 && this.fixed_time_adjusted > 0
        && this.interest_rate > 0) {
        const payload = {
          desired_amount: this.goal_total_value,
          monthly_required_amount: '0.00',
          fixed_time_adjusted: this.fixed_time_adjusted,
          interest_rate: this.interest_rate,
        };
        const {
          maximum_initial_amount,
        } = await this.calculate_maximum_initial_amount.execute(payload);
        this.maximum_initial_amount = Math.floor(parseFloat(maximum_initial_amount));
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.view.$refs.form_add_custom_goal.validate();
      }
    } catch {
      this.message_notifier.showErrorNotification(`${this.translate('errors.calculate_maximum_initial_amount')}`);
    } finally {
      this.is_loading = false;
    }
  }

  calculateMonthlyPayment = async () => {
    try {
      this.is_loading = true;
      await this.calculateMaximumInitialAmount();
      if (this.is_valid_form) {
        const desired_amount = this.goal_total_value;
        const payload = {
          desired_amount,
          initial_amount: this.goal_initial_amount,
          fixed_time_adjusted: this.fixed_time_adjusted,
        };
        const {
          custom_investor_goal_calculation,
        } = await this.calculate_monthly_payment_query.execute(payload);
        // eslint-disable-next-line max-len
        this.custom_investor_goal_dto.investor_goal.accumulated_amount = custom_investor_goal_calculation.accumulated_amount;
        this.monthly_payment_calculation = custom_investor_goal_calculation.monthly_required_amount;
      }
    } catch {
      this.message_notifier.showErrorNotification(`${this.translate('errors.calculate_monthly_payment')}`);
    } finally {
      this.is_loading = false;
    }
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = async () => {
    this.custom_investor_goal_dto.goal_name = this.goal_name;
    this.custom_investor_goal_dto.desired_amount = Number(this.goal_total_value);
    this.custom_investor_goal_dto.investor_goal.initial_amount = Number(this.goal_initial_amount);
    this.custom_investor_goal_dto.fixed_time_adjusted = this.fixed_time_adjusted.toString();
    this.custom_investor_goal_dto.custom_goal_type!.label = this.goal_name;
    // eslint-disable-next-line max-len
    this.custom_investor_goal_dto.investor_goal.monthly_required_amount = this.monthly_payment_calculation;
    this.view.$emit('setCustomGoalInvestorDto', this.custom_investor_goal_dto);
    this.view.$emit('nextStep');
  }
}
