




















































































































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import AddCustomInvestorGoalWizardCustomizeGoalModel
  from '@/vue-app/view-models/components/goals-dashboard/add-goals/add-custom-investor-goal-wizard-customize-goal-model';
// Domain
import { CustomInvestorGoalDto } from '@/modules/flagship/custom-investor-goal/domain/dtos/custom-investor-goal-dto';

@Component({
  name: 'AddCustomInvestorGoalWizardCustomizeGoal',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class AddCustomInvestorGoalWizardCustomizeGoal extends Vue {
  @PropSync('customGoalSelected', { type: Object, required: true })
  custom_investor_goal_dto!: CustomInvestorGoalDto;

  add_custom_investor_goal_customize = Vue.observable(
    new AddCustomInvestorGoalWizardCustomizeGoalModel(this),
  );

  created() {
    this.add_custom_investor_goal_customize.initialize(this.custom_investor_goal_dto);
  }
}
